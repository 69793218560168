import { useEffect, useState } from "react";
import { Link } from "@/helpers/common_helper";
import { pagelink } from "@/config/site";
import { _postURL } from "@/api/index";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay } from 'swiper';
import { useMediaQuery } from '@mantine/hooks';
import _ from 'lodash';

export default function HomepageBonusLatest(props) {
    const { t } = props;
    const currency_label = 'MYR';
    const [latestBonusList, setLatestBonusList] = useState([]);
    const isMobileView = useMediaQuery('(max-width:991px)');

    useEffect(async () => {
        try {
            let bonus = await _postURL("/api/home/getLatestBonus", {});  // Get Latest Bonus
            if(!_.isEmpty(bonus.data)){
                setLatestBonusList(bonus.data.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <section className="homepage-bonus-latest-sec py-1">
                <div className="custom_container2">
                    <div className='row justify-content-center'>
                        <div className='col-lg-12 col-sm-8 col-12 text-center p-0 p-lg-1'>
                            {isMobileView ? 
                            <div className="homepage-bonus-swiper-mobile"> { /* Mobile View */} {/* different way using _map */}
                                {_.isEmpty(latestBonusList) ? null : 
                                    <Swiper
                                        modules={[Autoplay]}
                                        direction={'vertical'}
                                        slidesPerView={1}
                                        loop={true}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false
                                        }}
                                    >
                                        <div className="swiper-wrapper">
                                            {!_.isEmpty(latestBonusList) ? 
                                                _.map(latestBonusList, lBL => (
                                                    <SwiperSlide key={lBL.bonus_id}>
                                                        <div className="homepage-bonus-latest-box">
                                                            <div className="homepage-bonus-latest-mobile-title">
                                                                <div className="homepage-bonus-latest-mobile-img">
                                                                    <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+lBL.logo}></img>
                                                                </div>
                                                                <div className="homepage-bonus-latest-mobile-header">
                                                                    <span className={"homepage-bonus-text-"+lBL.text_color}>{lBL.bonus_title}</span>
                                                                </div>
                                                            </div>
                                                            <div className="homepage-bonus-latest-mobile-content">
                                                                <div className="homepage-bonus-latest-mobile-left">
                                                                    <div className="homepage-bonus-latest-mobile-part">
                                                                        <span className="homepage-bonus-latest-mobile-text-left"><b>{t('Casino_profit')}: </b></span>
                                                                        <span className={"homepage-bonus-mobile-left-align homepage-bonus-text-"+lBL.text_color}><b>{currency_label}{lBL.company_profit}</b></span>
                                                                    </div>
                                                                    <div className="homepage-bonus-latest-mobile-part">
                                                                        <span className="homepage-bonus-latest-mobile-text-left"><b>{t('Edge')}: </b></span>
                                                                        <span className={"homepage-bonus-mobile-left-align homepage-bonus-text-"+lBL.text_color}><b>{lBL.edge}%</b></span>
                                                                    </div>
                                                                    <div className="homepage-bonus-latest-mobile-part">
                                                                        <span className="homepage-bonus-latest-mobile-text-left"><b>{t('Expected_edge')}: </b></span>
                                                                        <span className={"homepage-bonus-mobile-left-align homepage-bonus-text-"+lBL.text_color}><b>{currency_label}{lBL.expected_edge}</b></span>
                                                                    </div>
                                                                </div>
                                                                <div className="homepage-bonus-latest-mobile-right">
                                                                    <Link href={lBL.cta_link}>
                                                                        <a target="_blank">
                                                                            <button className="homepage-bonus-latest-mobile">{t('View_more')}</button>
                                                                        </a>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                                : 
                                                null
                                            } 
                                        </div>
                                    </Swiper>
                                }
                            </div>
                            :
                            <div className="homepage-bonus-swiper"> { /* Desktop View */} {/* different way using _reduce */}
                                {_.isEmpty(latestBonusList) ? null : 
                                    <Swiper
                                        modules={[Autoplay]}
                                        direction={'vertical'}
                                        slidesPerView={1}
                                        loop={true}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false
                                        }}
                                    >
                                        <div className="swiper-wrapper">
                                            {
                                                _.reduce(latestBonusList, (result, value, key) => {
                                                    result.push(
                                                        <SwiperSlide key={key}>
                                                            <div className="homepage-bonus-latest-box">
                                                                <div className="homepage-bonus-latest-img">
                                                                    <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+value.logo}></img>
                                                                </div>
                                                                <div className="homepage-bonus-title">
                                                                    <span className={"homepage-bonus-text-"+value.text_color}>{value.bonus_title}</span>
                                                                </div>
                                                                <div className="homepage-bonus-content">
                                                                    <b>{t('Casino_profit')}: <span className={"homepage-bonus-text-"+value.text_color}>{currency_label}{value.company_profit}</span></b>
                                                                </div>
                                                                <div className="homepage-bonus-content">
                                                                    <b>{t('Edge')}: <span className={"homepage-bonus-text-"+value.text_color}>{value.edge}%</span></b>
                                                                </div>
                                                                <div className="homepage-bonus-content">
                                                                    <b>{t('Expected_edge')}: <span className={"homepage-bonus-text-"+value.text_color}>{currency_label}{value.expected_edge}</span></b>
                                                                </div>
                                                                <div className="homepage-bonus-button-sec">
                                                                    <Link href={value.cta_link}>
                                                                        <a target="_blank">
                                                                            <button className="homepage-bonus-button">{t('View_more')}</button>
                                                                        </a>
                                                                    </Link>
                                                                </div>
                                                            </div> 
                                                        </SwiperSlide>
                                                    )
                                                    return result;
                                                }, [])
                                            }
                                        </div>
                                    </Swiper>
                                }
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}