import { useEffect, useState } from "react";
import { pagelink } from "@/config/site";
import { _postURL } from "@/api/index";
import _ from 'lodash';
import { useRouter } from "next/router";

const LatestSectionStarRate = ({rate = 0}) =>{
    let starDiv = [];
    for (let i = 1; i <= 5; i++) {
        starDiv.push(
            <li key={i}>
                {rate >= i ?
                    <img src="/assets/images/review-star-filled.svg" alt="" className="img-fluid w-100" />
                :
                    <img src="/assets/images/review-star-empty.svg" alt="" className="img-fluid w-100" />
                }
            </li>
        )
    }
    return(
        <ol className="homepage-recent-company-star">
            {starDiv}
        </ol>
    )
}

const StarRate = ({rate = 0}) =>{
    let starDiv = [];
    for (let i = 1; i <= 5; i++) {
        starDiv.push(
            <li key={i}>
                {rate >= i ?
                    <img src="/assets/images/review-star-filled.svg" alt="" className="img-fluid w-100" />
                :
                    <img src="/assets/images/review-star-empty.svg" alt="" className="img-fluid w-100" />
                }
            </li>
        )
    }
    return(
        <ol className="homepage-rank-box-star">
            {starDiv}
        </ol>
    )
}

export default function HomepageLatest(props) {
    const { t } = props;
    const [latestReviewList, setLatestReviewList] = useState([]);
    const [latestStarList, setLatestStarList] = useState([]);
    const [latestDiamondList, setLatestDiamondList] = useState([]);
    const [latestArticleList, setLatestArticleList] = useState([]);
    const router = useRouter();

    useEffect(async () => {
        try {
            let review = await _postURL("/api/home/getLatestReview", {});  // Get Latest Star Ranking Result
            if(!_.isEmpty(review.data)){
                setLatestReviewList(review.data);
            }

            let star = await _postURL("/api/home/getLatestStar", {});  // Get Latest Star Ranking Result
            if(!_.isEmpty(star.data)){
                setLatestStarList(star.data);
            }

            let diamond = await _postURL("/api/home/getLatestDiamond", {});  // Get Latest Diamond Ranking Result
            if(!_.isEmpty(diamond.data)){
                setLatestDiamondList(diamond.data);
            }
            
            let latestArticle = await _postURL("/api/home/getLatestArticle", {});  // Get Latest Article Result
            if(!_.isEmpty(latestArticle.data)){
                setLatestArticleList(latestArticle.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    }, []);

    return (
        <>
            <section className="homepage-latest-article">
                <div className='custom_container2'>
                    <div className="homepage-recent-company-sec">
                        { !_.isEmpty(latestReviewList) ?
                            <div className="homepage-latest-article-title">
                                <h1 className='homepage_title custom_colorPrimary'>
                                    {t('Latest_review')}
                                </h1> 
                            </div>: null
                        }
                        <div className="homepage-recent-company-content">
                            {
                                _.reduce(latestReviewList, (result, value, key) => {
                                    result.push(
                                        <div key={key} className="homepage-recent-company-box">
                                            <div className="homepage-recent-company-box-inner">
                                                <div className="homepage-recent-company-box-content">
                                                    <div className="homepage-recent-company-logo">
                                                        <a href={pagelink.review.singleReview+value.custom_url} style={{ color: 'black', cursor: 'pointer' }}>
                                                            <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+value.logo}></img>
                                                        </a>
                                                    </div>
                                                    <div className="homepage-recent-company-detail">
                                                        <a href={pagelink.review.singleReview+value.custom_url} style={{ color: 'black', cursor: 'pointer' }}>
                                                            <div className="homepage-recent-company-title">{value.display_name}</div>
                                                        </a>
                                                        <div className="homepage-recent-company-star-box">
                                                            <LatestSectionStarRate rate={value.rating} />
                                                        </div>
                                                        <div className="homepage-recent-company-diamond-box">
                                                            <a href={pagelink.review.vote} class="d-flex align-items-center">
                                                                <div className="homepage-recent-company-diamond">
                                                                    <img src="/assets/images/diamond-user.svg" alt="" className="img-fluid w-100"/>
                                                                </div>
                                                                <h3 className="custom_colorPrimary homepage-recent-company-diamond-amt">{value.total_diamond}</h3>
                                                            </a>
                                                        </div>
                                                        <div className="homepage-recent-company-button-box">
                                                            <a href={pagelink.review.singleReview+value.custom_url} style={{ color: 'black', cursor: 'pointer' }}>
                                                                <div className="homepage-recent-company-button">
                                                                    {t('See_review')}
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="homepage-recent-company-tag">New!</div>
                                            </div>
                                        </div>
                                    )
                                    return result;
                                }, [])
                            }
                        </div>
                    </div>
                    <div className="homepage-ranking-sec">
                        <div className="homepage-ranking-box">
                            <div className="homepage-rank-box">
                                <div className="homepage-rank-box-title">
                                    <h1 className="homepage_title custom_colorPrimary" style={{ paddingBottom: '10px' }}>
                                        {t('Ranking')}
                                    </h1>
                                    <p className="homepage-view-more">
                                        <a href={pagelink.review.grade} style={{ color: '#1b1b1b' }}>{t('View_more')}</a>
                                    </p>
                                </div>
                                {
                                    _.reduce(latestStarList, (result, value, key) => {
                                        result.push(
                                            <div key={key} className="homepage-rank-box-row">
                                                <div className="homepage-rank-box-content">
                                                    <h5 className="custom_h5 homepage-rank-box-rank">#{key+1}</h5>
                                                    <div className="homepage-rank-box-rank-title">
                                                        <div className="homepage-rank-box-rank-logo">
                                                            <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+value.logo}></img>
                                                        </div>
                                                        <h4 className="custom_h4 custom_colorPrimary homepage-rank-box-rank-company">{value.display_name}</h4>
                                                    </div>
                                                    <StarRate rate={value.rating} />
                                                </div>
                                            </div>
                                        )
                                        return result;
                                    }, [])
                                }
                            </div>
                        </div>
                        <div className="homepage-ranking-box">
                            <div className="homepage-rank-box">
                                <div className="homepage-rank-box-title">
                                    <h1 className="homepage_title custom_colorPrimary" style={{ paddingBottom: '10px' }}>
                                        {t('Diamond')}
                                    </h1>
                                    <p className="homepage-view-more">
                                        <a href={pagelink.review.vote} style={{ color: '#1b1b1b' }}>{t('View_more')}</a>
                                    </p>
                                </div>
                                {
                                    _.reduce(latestDiamondList, (result, value, key) => {
                                        result.push(
                                            <div key={key} className="homepage-rank-box-row">
                                                <div className="homepage-rank-box-content">
                                                    <h5 className="custom_h5 homepage-rank-box-rank">#{key+1}</h5>
                                                    <div className="homepage-rank-box-vote-title">
                                                        <div className="homepage-rank-box-rank-logo">
                                                            <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+value.logo}></img>
                                                        </div>
                                                        <h4 className="custom_h4 custom_colorPrimary homepage-rank-box-rank-company">{value.display_name}</h4>
                                                    </div>
                                                    <div className="homepage-rank-box-vote">
                                                        <div className="homepage-rank-box-diamond">
                                                            <div className="homepage-rank-box-diamond-img">
                                                                <img src="/assets/images/diamond-user.svg" alt="" className="img-fluid w-100"/>
                                                            </div>
                                                            <h4 className="custom_h3 custom_colorPrimary" style={{ paddingLeft: '10px' }}>{value.total_diamond}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        return result;
                                    }, [])
                                }
                            </div>
                        </div>
                    </div>
                    <div className="homepage-latest-article-sec">
                        <div className="homepage-latest-article-title">
                            <h1 className='homepage_title custom_colorPrimary'>
                                {t('Latest_article')}
                            </h1>
                            <p className="homepage-view-more">
                                <a href={pagelink.guide.homepage} style={{ color: '#1b1b1b' }}>{t('View_more')}</a>
                            </p>
                        </div>
                        <div className="homepage-latest-article-highlight">
                            <div className="homepage-article-highlight-inner">
                                <div>
                                    <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+latestArticleList?.[0]?.cover_image}></img>
                                </div>
                                <h2 className="homepage-article-title">
                                    {latestArticleList?.[0]?.subject}{latestArticleList?.[0]?.subject}
                                </h2>
                                <div className="homepage-article-highlight-content">
                                    <p className="homepage-article-title" dangerouslySetInnerHTML={{ __html: latestArticleList?.[0]?.description }}></p>
                                    <div className="homepage-article-highlight-datetime">
                                        <div className='custom_p3' style={{color: '#1b1b1b'}}>
                                            {latestArticleList?.[0]?.created_time}
                                        </div>
                                        <a href={pagelink.article.articleMidUrl+latestArticleList?.[0]?.custom_url}>
                                            <div className='custom_p3' style={{textDecoration: 'underline', color: '#1b1b1b'}}>
                                                {t('Read_full_article')}
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="homepage-article-remaining">
                                {
                                    _.reduce(latestArticleList, (result, value, key) => {
                                        if(key > 0){
                                            result.push(
                                                <a key={key} href={pagelink.article.articleMidUrl+value.custom_url}>
                                                    <div className="homepage-article-box">
                                                        <div className="homepage-article-box-left">
                                                            <img className="img-fluid w-100" alt="" src={process.env.NEXT_PUBLIC_FS_URL+value.cover_image}></img>
                                                        </div>
                                                        <div className="homepage-article-box-right">
                                                            <div className="homepage-article-box-content">
                                                                <div className="homepage-article-box-title">
                                                                    <h4 className='homepage-article-title' style={{flex: 1}}>
                                                                    {value.subject}
                                                                    </h4>
                                                                </div>
                                                                <div className='custom_p3' style={{color: '#1b1b1b'}}>
                                                                    {value.created_time}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            )
                                        }
                                        return result;
                                    }, [])
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}