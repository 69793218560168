
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect,useState } from 'react';
import { Link } from "@/helpers/common_helper";
import { pagelink } from "@/config/site";
import { _getURL } from "@/api/index";
import { Grid, Text, Image, ScrollArea } from '@mantine/core';
import Redux, { updatePath } from "@/action";
import Subscription from "@/components/section/subscription";
import Modal from 'react-modal';
import Head from 'next/head';
import { BsArrowRightCircle, BsBorderBottom } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import { useMediaQuery } from '@mantine/hooks';
import HomepageLatest from "@/components/section/homepage";
import HomepageBonusLatest from "@/components/section/homepageBonus";
import _ from 'lodash';

const content = [
  {contentText: "Understand multiple sites at one place", contentImagePath: "/assets/images/homepage/book-stack.png"},
  {contentText: "Complete casino guide and tips", contentImagePath: "/assets/images/homepage/book.png"},
  {contentText: "Check and evaluate before playing", contentImagePath: "/assets/images/homepage/project.png"},
  {contentText: "Support your favourite igaming sites", contentImagePath: "/assets/images/homepage/vote.png"}
]

const content2 = [
  {contentText: "Increase brand exposure", contentImagePath: "/assets/images/homepage/brand-awareness.png"},
  {contentText: "Improve search engine ranking", contentImagePath: "/assets/images/homepage/search-engine-optimization.png"},
  {contentText: "Strengthen users’ trust and credibility", contentImagePath: "/assets/images/homepage/trust.png"},
  {contentText: "Trusted reviews to improve experiences", contentImagePath: "/assets/images/homepage/quality.png"}
]

export default function Index(props) {
  const { store, dispatch } = Redux();
  const { t, session } = props;

  const circleIndicatorStyle = { borderRadius: 30, width: 10, height: 10, backgroundColor: '#bb9b6a' };

  const isMobileView = useMediaQuery('(max-width:767px)');

  return (
    <>
      {/** Banner */}
      {/* <section className="banner-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="text-box">
                <h1>{t('Homepage_text_1')}</h1>
                <h4><img src="/assets/images/logo.png" alt="" /> {t('Homepage_text_2')}</h4>
                <h4 className="m-0">{t('Homepage_text_3')}</h4>
                <span><img src="/assets/images/banner-badge.png" alt="" /></span>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/** Slider */}
      {/* <section className="what-we-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="what-we-inner">
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-interval="true">
                  <div className="carousel-indicators">
                    <ul>
                      <li>
                        <button type="button" data-bs-target="#carouselExampleIndicators" 
                          data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1">{t('What_we_do')}</button>
                      </li>
                      <li>
                        <button type="button" data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="1" aria-label="Slide 2">{t('Mission_vision')}</button>
                      </li>
                      <li>
                        <button type="button" data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to="2" aria-label="Slide 3">{t('Our_team')}</button>
                      </li>
                    </ul>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="text-box">
                        <h4>{t('What_we_do')}</h4>
                        <p>You'll find everything related to igaming sites, 
                          including honest reviews, popularity rank, in-depth 
                          guides and latest trends. In {t('Site_name')}, we 
                          provide an unbiased platform to identify the safest 
                          and premium igaming experience. No matter what 
                          criteria you're looking for, we've got recommendations 
                          for you. It's all about finding the best fit. 
                        </p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="text-box">
                        <h4>{t('Mission_vision')}</h4>
                        <p><b>Mission</b> <br />
                          - Identify the safest and premium igaming experience. <br />
                          - To improve users' experiences and enhance the quality of iGaming platforms.<br />
                          <b>Visions</b> <br />
                          - To be the industry-leading iGaming reviews provider & platform, delivering honest and unbiased reviews.
                        </p>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="text-box">
                        <h4>{t('Our_team')}</h4>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry.
                            Lorem Ipsum has been the industry's standard dummy text
                            ever since the 1500s, whe n a n printer took a galley of type and
                            scrambled
                            it to make a type specimen book. It has survived not only
                            five centuries, but also the leap into electronic typesetting, remaining
                            essentially unchanged. It was popularised in the 1960s with
                            the release of Letraset sheets containing Lorem Ipsum passages, and more
                            recently with desktop publishing software like Aldus
                            PageMaker including versions of Lorem Ipsum.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/** Grading Casino */}
      {/* <section className="grade-casino-sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 order-xl-1 order-2">
              <div className="text-box">
                <h2>How we <span> grade </span> online casinos?</h2>
                <br/><br/>
                <div>
                  <h3><b>To business: </b></h3>
                    <ul className='m-0'>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Increase brand exposure</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Improve search engine ranking</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Strengthen users' trust and credibility</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Keep track of reviews to improve users' experiences</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Get yourself detailed analyses and rating reports </li>
                    </ul>
                </div>
                <br/><br/>
                <div>
                  <h3><b>To users: </b></h3>
                    <ul className='m-0'>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Better understand multiple sites-all at one place, like a summary study guide.</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Check and evaluate everything before playing.</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>Support by voting for your favourite igaming sites on the Ugrado Voting rank.</li>
                      <li className='m-0 ps-5 fs-4' style={ { display: 'list-item', listStyle: 'inside' } }>A complete casino guide and tips before getting started. </li>
                    </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-5 order-xl-2 order-1">
              <div className="image-holder">
                <img src="/assets/images/grade-casino-img.png" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/** Review Casino */}
      {/* <section className="review-casino-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title" style={ { maxWidth: 'unset' } }>
                <h2>Why trust <span> {t('Site_name')} </span> for our <br /> review of online casinos?</h2>
              </div>
              <div className="review-casino-inner">
                <div className="text-box">
                  <p>Our team of experts continuously researches and reviews igaming sites from all over the internet, while providing transparent and unbiased reviews to weed out unsafe and unpleasant options. The best part? Ugrado also offers detailed analyses and rating reports to improve your site’s user experiences. Make sure you claim your reputation and reviews. It can be a source for acquiring new customers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Head>
        <title>UGRADO Transparent iGaming Service Test Labs Rating & Awards</title>
        <meta name='title' content='UGRADO Transparent iGaming Service Test Labs Rating & Awards'></meta>
        <meta itemProp='title' content='UGRADO Transparent iGaming Service Test Labs Rating & Awards'></meta>
        <meta property='og:title' content='UGRADO Transparent iGaming Service Test Labs Rating & Awards'></meta>
        <meta name='description' content="Ugrado is a leading transparent platform for iGaming player ratings and awards of great quality brands. Guide & instant experiences to help players make better choices."></meta>
        <meta itemProp='description' content="Ugrado is a leading transparent platform for iGaming player ratings and awards of great quality brands. Guide & instant experiences to help players make better choices."></meta>
        <meta property='og:description' content="Ugrado is a leading transparent platform for iGaming player ratings and awards of great quality brands. Guide & instant experiences to help players make better choices."></meta>
        <meta name="keywords" content=""></meta>
      </Head>

      <div className='page-content'>
        <section className="homepage-sec">
          <div className="homepage-swiper">
            <Swiper
              modules={[Autoplay, Pagination]}
              pagination={{clickable: true}}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
            >
              <div className="swiper-wrapper">
                <SwiperSlide key='1'>
                  <div className="title text-center">
                    {isMobileView ? 
                      <a href="https://ugradoawards.com/">
                        <img className="mx-auto img-fluid" alt="" src="/assets/images/homepage/slider-banner-7-mobile.png"></img> 
                      </a>
                      : 
                      <a href="https://ugradoawards.com/">
                        <img className="mx-auto img-fluid w-100" alt="" src="/assets/images/homepage/slider-banner-7.png"></img>
                      </a>
                    }
                  </div>
                </SwiperSlide>
                <SwiperSlide key='2'>
                  <div className="title text-center">
                    {isMobileView ? 
                      <a href={pagelink.review.grade}>
                        <img className="mx-auto img-fluid" alt="" src="/assets/images/homepage/slider-banner-3-mobile.png"></img> 
                      </a>
                      : 
                      <a href={pagelink.review.grade}>
                        <img className="mx-auto img-fluid w-100" alt="" src="/assets/images/homepage/slider-banner-3.png"></img>
                      </a>
                    }
                  </div>
                </SwiperSlide>
                <SwiperSlide key='3'>
                  <div className="title text-center">
                    {isMobileView ? 
                      <img className="mx-auto img-fluid" alt="" src="/assets/images/homepage/slider-banner-4-mobile.png"></img> 
                      : 
                      <img className="mx-auto img-fluid w-100" alt="" src="/assets/images/homepage/slider-banner-4.png"></img>
                    }
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </section>

        <HomepageBonusLatest {...props}/>

        {/* 
          trustpilot & sitejabber
          <HomepageStaticReviewLatest {...props}/>
        */}
        
        <HomepageLatest {...props}/> 
        

        <section className="homepage-sec py-5">
          <div className="custom_container2">
            <div className="title text-left">
              <div className="mx-auto fw-bold clearfix d-flex align-items-end">
                <h1 className='homepage_title custom_colorPrimary'>{t('What_we_do')}</h1>
                <h2 className="float-start ms-3 w-25"><img className="w-25" alt="" src='/assets/images/homepage/question.png'></img></h2>
              </div>
            </div>
            <div className="homepage-content-top pt-4">
              <p>
                You'll find everything related to igaming sites, including honest reviews, popularity rank, in-depth guides and latest trends. <br/><br/>
                In Ugrado, we provide an unbiased platform to identify the safest and premium igaming experience. No matter what criteria you're looking for, we've got recommendations for you. It's all about finding the best fit.
              </p>
            </div>
          </div>
        </section>

        <section className="homepage-sec py-5">
          <div className="custom_container2">
            <div className="title text-center">
              <h2>{t('Why_ugrado')}</h2>
            </div>
            <div className='why-ugrado-list'>
              <div className='why-ugrado-row'>
                <div className='why-ugrado-item why-ugrado-item-large'>
                  <div className='why-ugrado-item-title'>
                    <h2>
                      To players:
                    </h2>
                  </div>
                  <div className='why-ugrado-item-image'>
                    <img className="img-fluid w-100" alt="" src='/assets/images/homepage/man.png'></img>
                  </div>
                  <a href='/review/how-we-review' className='why-ugrado-item-seeMore'>
                    <h4>
                      {t('See_more')}
                    </h4>
                    <h3>
                      <BsArrowRightCircle />
                    </h3>
                  </a>
                </div>
                {
                  _.reduce(content, (result, value, key) => {
                    result.push(
                      <div key={key} className='why-ugrado-item'>
                        <div className='why-ugrado-item-image'>
                          <img className="img-fluid w-100" alt="" src={value.contentImagePath}></img>
                        </div>
                        <div className='why-ugrado-item-content'>
                          <h4>
                            <FaCheckCircle className='custom_colorDarkGreen'/>
                          </h4>
                          <h5>
                            {value.contentText}
                          </h5>
                        </div>
                      </div>
                    )
                    return result;
                  }, [])
                }
              </div>
              <div className='why-ugrado-row why-ugrado-row-right'>
                {
                  _.reduce(content2, (result, value, key) => {
                    result.push(
                      <div key={key} className='why-ugrado-item'>
                        <div className='why-ugrado-item-image'>
                          <img className="img-fluid w-100" alt="" src={value.contentImagePath}></img>
                        </div>
                        <div className='why-ugrado-item-content'>
                          <h4>
                            <FaCheckCircle className='custom_colorDarkGreen' />
                          </h4>
                          <h5>
                            {value.contentText}
                          </h5>
                        </div>
                      </div>
                    )
                    return result;
                  }, [])
                }
                <div className='why-ugrado-item why-ugrado-item-large why-ugrado-item-large-right'>
                  <div className='why-ugrado-item-title'>
                    <h2>
                      To business:
                    </h2>
                  </div>
                  <div className='why-ugrado-item-image'>
                    <img className="img-fluid w-100" alt="" src='/assets/images/homepage/business.png'></img>
                  </div>
                  <a href='' className='why-ugrado-item-seeMore'>
                    <h4>
                      {t('See_more')}
                    </h4>
                    <h3>
                      <BsArrowRightCircle />
                    </h3>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="faq-inner pt-4">
              <div className="row">
                <div className="row g-0 col-lg-5 col-md-12 homepage-content-middle-box-left">
                  <div className="homepage-content-middle-box-layout-left">
                    <span className="homepage-content-middle-title">To players:</span>
                    <img className="mx-auto h-25 my-5" src='/assets/images/homepage/man.png'></img>
                    <a href='/review/how-we-review' className="homepage-content-middle-subtitle">{t('See_more')}&nbsp;<i className="fa-regular fa-circle-arrow-right"></i></a>
                  </div>
                </div>
                <div className="row g-0 col-lg-7 col-md-12">
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-0">
                    <img className="h-25 mb-4" src="/assets/images/homepage/book-stack.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Understand multiple sites at one place</div>
                    </div>
                  </div>
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-1" style={{ borderTopRightRadius: '20px' }}>
                    <img className="h-25 mb-4" src="/assets/images/homepage/book.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Complete casino guide and tips</div>
                    </div>
                  </div>
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-2">
                    <img className="h-25 mb-4" src="/assets/images/homepage/project.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Check and evaluate before playing</div>
                    </div>
                  </div>
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-3" style={{ borderBottomRightRadius: '20px' }}>
                    <img className="h-25 mb-4" src="/assets/images/homepage/vote.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Support your favourite igaming sites</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row my-4"></div>
              <div className="row">
                <div className="row g-0 col-lg-7 col-md-12">
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-0" style={{ borderTopLeftRadius: 20 }}>
                    <img className="h-25 mb-4" src="/assets/images/homepage/brand-awareness.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Increase brand exposure</div>
                    </div>
                  </div>
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-1">
                    <img className="h-25 mb-4" src="/assets/images/homepage/search-engine-optimization.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Improve search engine ranking</div>
                    </div>
                  </div>
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-2" style={{ borderBottomLeftRadius: 20 }}>
                    <img className="h-25 mb-4" src="/assets/images/homepage/trust.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Strengthen users' trust and credibility</div>
                    </div>
                  </div>
                  <div className="col-6 homepage-content-feature homepage-content-feature-order-3">
                    <img className="h-25 mb-4" src="/assets/images/homepage/quality.png"></img>
                    <div className="row col-9 mx-auto fw-bold">
                      <div className="col-1"><i className="fa-solid fa-circle-check homepage-check"></i></div>
                      <div className="col homepage-content-middle-text">Trusted reviews to improve experiences</div>
                    </div>
                  </div>
                </div>
                <div className="row g-0 col-lg-5 col-md-12 homepage-content-middle-box-right">
                  <div className="col-12 homepage-content-middle-box-layout-right">
                    <span className="homepage-content-middle-title">To business:</span>
                    <img className="mx-auto h-25 my-5" src="/assets/images/homepage/business.png"></img>
                    <a href='' className="homepage-content-middle-subtitle">{t('See_more')}&nbsp;<i className="fa-regular fa-circle-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section className="homepage-sec py-5">
          <div className="custom_container2">
            <div className="title text-left">
              <h2>{t('How_ugrado_review')}</h2>
            </div>
            <div className="homepage-content-top pt-4">
              <p>Our team of experts continuously researches and reviews igaming sites from all over the internet, while providing transparent and unbiased reviews to weed out unsafe and unpleasant options. The best part? Ugrado also offers detailed analyses and rating reports to improve your site’s user experiences. Make sure you claim your reputation and reviews. It can be a source for acquiring new customers.</p>
            </div>
            <a href='/review/how-we-review' className='homepage-content-middle-seeMore'>
              <h3>
                {t('See_more')}
              </h3>
              <h2>
                <BsArrowRightCircle />
              </h2>
            </a>
          </div>
        </section>

        {/** FAQ */}
        <section className="homepage-sec py-5 faq-section">
          <div className="custom_container2">
            <div className="title text-center">
              <h2>{t('Faq_desc')}</h2>
            </div>
            <div className="faq-inner pt-4">
              <div itemScope itemType='https://schema.org/FAQPage' className="accordion accordion-flush" id="accordionExample">
                <div itemScope itemProp='mainEntity' itemType='https://schema.org/Question' className="accordion-item custom_accordion">
                  <h2 itemProp='name' className="accordion-header" id="headingOne">
                    <button className="accordion-button homepage-faq" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1" style={{ color: '#000000', backgroundColor: 'transparent' }}>
                      <h4>What is Ugrado?</h4>
                      <h4>
                        <AiOutlineMinusCircle className='custon_accordion_icon_close' />
                        <AiOutlinePlusCircle className='custon_accordion_icon_open' />
                      </h4>
                    </button>
                  </h2>
                  <div itemScope itemProp='acceptedAnswer' itemType='https://schema.org/Answer' id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div itemProp='text' className="accordion-body homepage-faq-ans">
                      <p>
                        Ugrado provides an unbiased platform to identify the safest and premium igaming
                        experience. You'll find everything related to igaming sites, including honest reviews, popularity
                        rank, in-depth guides and latest trends. No matter what criteria you're looking for, we've got 
                        the best fit for you. 
                      </p>
                    </div>
                  </div>
                </div>
                <div itemScope itemProp='mainEntity' itemType='https://schema.org/Question' className="accordion-item custom_accordion">
                  <h2 itemProp='name' className="accordion-header" id="headingOne">
                    <button className="accordion-button homepage-faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2" style={{ color: '#000000', backgroundColor: 'transparent' }}>
                      <h4>Is Ugrado an online casino?</h4>
                      <h4>
                        <AiOutlineMinusCircle className='custon_accordion_icon_close' />
                        <AiOutlinePlusCircle className='custon_accordion_icon_open' />
                      </h4>
                    </button>
                  </h2>
                  <div itemScope itemProp='acceptedAnswer' itemType='https://schema.org/Answer' id="collapse-2" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div itemProp='text' className="accordion-body homepage-faq-ans">
                      <p>
                        Ugrado is not an online casino, nor provides any gambling services. Ugrado is an independent online review platform providing trusted and unbiased reviews and guides, for both online casino providers and players.
                      </p>
                    </div>
                  </div>
                </div>
                <div itemScope itemProp='mainEntity' itemType='https://schema.org/Question' className="accordion-item custom_accordion">
                  <h2 itemProp='name' className="accordion-header" id="headingOne">
                    <button className="accordion-button homepage-faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3" style={{ color: '#000000', backgroundColor: 'transparent' }}>
                      <h4>How Ugrado Review Works?</h4>
                      <h4>
                        <AiOutlineMinusCircle className='custon_accordion_icon_close' />
                        <AiOutlinePlusCircle className='custon_accordion_icon_open' />
                      </h4>
                    </button>
                  </h2>
                  <div itemScope itemProp='acceptedAnswer' itemType='https://schema.org/Answer' id="collapse-3" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div itemProp='text' className="accordion-body homepage-faq-ans">
                      <p>
                        Ugrado expert review team build up with experienced people coming from gambling industry and review backgrounds. They continuously research and review igaming sites all over the world, providing transparent and unbiased reviews, based on the in-house Published Review Scale.
                      </p>
                    </div>
                  </div>
                </div>
                <div itemScope itemProp='mainEntity' itemType='https://schema.org/Question' className="accordion-item custom_accordion">
                  <h2 itemProp='name' className="accordion-header" id="headingOne">
                    <button className="accordion-button homepage-faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4" style={{ color: '#000000', backgroundColor: 'transparent' }}>
                      <h4>How Ugrado online reviews help online casinos?</h4>
                      <h4>
                        <AiOutlineMinusCircle className='custon_accordion_icon_close' />
                        <AiOutlinePlusCircle className='custon_accordion_icon_open' />
                      </h4>
                    </button>
                  </h2>
                  <div itemScope itemProp='acceptedAnswer' itemType='https://schema.org/Answer' id="collapse-4" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div itemProp='text' className="accordion-body homepage-faq-ans">
                      <p>
                        With Ugrado, online casino sites like you can: increase reputation and online visibility, build users' trust, and indirectly, turn people who search for online reviews into your next customers. The best part?  Ugrado also offers detailed analyses and rating reports to improve your site’s user experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/** Book Section */}
        {/* <section className="community-sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="community-inner">
                  <div className="text-box d-block d-xl-none">
                    <h3>About {t('Site_name')}</h3>
                    <p>Why we create {t('Site_name')} for the community</p>
                  </div>
                  <div className="image-holder">
                    <img src="/assets/images/book-img.png" alt="" className="img-fluid d-none d-xl-block" />
                    <img src="/assets/images/book-img-mobile.png" alt="" className="img-fluid d-block d-xl-none" />
                  </div>
                  <div className="text-box">
                    <h3 className="d-none d-xl-block">About {t('Site_name')}</h3>
                    <p className="d-none d-xl-block">Why we create {t('Site_name')} for the community</p>
                    <a href="#">Learn more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/** Subscription Part */}
        <Subscription {...props}/>

      </div>
    </>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}